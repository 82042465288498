import { Guid } from '@komo-tech/core/models/Guid';

import { useReactQuerySync } from '@/common/data/react-query';
import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDescriptor } from '@/common/models/CardDescriptor';

import { PublicCardsCacheKeys as CacheKeys } from '../_cacheKeys';

interface Options {
  cardId: Guid;
  onDescriptorChange?: (card: CardDescriptor) => void;
  onTypedChange?: (card: FrontAnyCard) => void;
}

export function useFrontCardsCacheSync({
  cardId,
  onTypedChange,
  onDescriptorChange
}: Options) {
  useReactQuerySync({
    items: [
      {
        name: 'cd',
        cacheKey: CacheKeys.descriptor(cardId),
        callback: onDescriptorChange
      },
      {
        name: 'tc',
        cacheKey: CacheKeys.typed(cardId),
        callback: onTypedChange
      }
    ]
  });
}
