import { Guid } from '@komo-tech/core/models/Guid';
import { UrlBuilder } from '@komo-tech/core/utils/UrlBuilder';

import { FrontCardStateParameter } from '@/common/components/Card/shared/CardConstants';
import { getSiteUrl } from '@/common/utils/SiteFunctions';
import { TriviaCardRouteStates } from '@/front/components/site/trivia-card/context/TriviaCardRouteStates';

export const TriviaGameplayIDParameterName = 'gameplay';

export const getTriviaGameplayModalUrl = (
  cardId: Guid,
  siteDomain: string,
  gameplayId?: Guid
) => {
  let builder = new UrlBuilder()
    .withBaseUrl(getSiteUrl(siteDomain))
    .withQuery('card', cardId.toString())
    .withQuery(FrontCardStateParameter, TriviaCardRouteStates.Gameplay);

  if (gameplayId)
    builder = builder.withQuery(
      TriviaGameplayIDParameterName,
      gameplayId.toString()
    );

  return builder.build();
};

export const getBigScreenUrl = (cardId: Guid, siteDomain?: string) => {
  return `${getSiteUrl(siteDomain)}/trivia/big-screen/${cardId}`;
};
