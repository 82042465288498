import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { TriviaFormVariant } from '@/common/models/trivia/shared/TriviaFormVariant';
import { TriviaProperties } from '@/common/models/trivia/shared/TriviaProperties';

import { getBigScreenUrl } from '../TriviaGameFunctions';
import { AdminTriviaGame } from './AdminTriviaGame';

export class AdminTrivia implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Trivia;
  name: string;
  gameId: Guid;
  imageUrl: string = '';

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  game: AdminTriviaGame;
  properties: TriviaProperties;

  constructor(props?: Partial<AdminTrivia>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.gameId = Guid.valueOrNew(props.gameId);
    this.game = new AdminTriviaGame(props.game);
    this.properties = new TriviaProperties(props.properties);
  }

  getCoverUploadPath() {
    return `site/${this.siteId}/card/${this.id}/cover`;
  }

  getSubmitButtonProps(properties: SitePageProperties) {
    return {
      color: this.getButtonTextColor(properties),
      backgroundColor: this.getButtonBackgroundTextColor(properties)
    };
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getButtonBackgroundTextColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundTextColor(properties);
  }

  getCoverButtonTextProps() {
    return {
      text: this.properties.CoverButtonText,
      defaultText: this.properties.DefaultCoverButtonText
    };
  }

  get isBasicFormVariant() {
    return this.properties.FormVariant === TriviaFormVariant.Basic;
  }

  getBigScreenLink(siteDomain: string) {
    return getBigScreenUrl(this.id, siteDomain);
  }
}
