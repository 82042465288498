import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { ScheduleTime } from '@komo-tech/core/models/ScheduleTime';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { DistributionLifeCycleEvent } from '@/common/models/communications/DistributionLifeCycleEvent';
import {
  AnsweredQuestionsSummary,
  RevealedAnswersSummary,
  SegmentState
} from '@/common/models/predictive/types';
import { getTagScope, KnownTags } from '@/common/models/tags/KnownTags';

import { Predictive } from './Predictive';
import { PredictiveAnswerResult } from './PredictiveAnswerResult';
import { PredictiveAnswerStyles } from './PredictiveAnswerStyles';
import { PredictiveQuestion } from './PredictiveQuestion';
import { PredictiveSegmentProperties } from './PredictiveSegmentProperties';

export class PredictiveSegment {
  readonly id: Guid;
  readonly predictiveId: Guid;
  readonly title: string = '';
  readonly imageUrl: string = '';

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  readonly order: number = 1;
  readonly startedAt: ScheduleTime;
  readonly closedAt: ScheduleTime;
  readonly isVisible: boolean;
  readonly isArchived: boolean;
  readonly properties: PredictiveSegmentProperties;

  questions: PredictiveQuestion[] = [];

  constructor(props: Partial<PredictiveSegment> = {}) {
    Object.assign(this, props);
    this.id = props.id ? new Guid(props.id) : Guid.newGuid();
    this.startedAt = new ScheduleTime(props.startedAt);
    this.closedAt = new ScheduleTime(props.closedAt);
    this.questions = (props.questions || [])
      .map((q) => new PredictiveQuestion(q))
      .sort(orderByAscending);
    this.isVisible = asBoolean(props.isVisible);
    this.isArchived = asBoolean(props.isArchived);
    this.properties = new PredictiveSegmentProperties(props.properties);
  }

  getRevealedAnswersSummary(): RevealedAnswersSummary {
    let total = 0;
    let revealed = 0;
    for (const question of this.questions.filter(
      (q) => q.style !== PredictiveAnswerStyles.Survey
    )) {
      total++;
      if (question.answerRevealed || question.properties.HasNoCorrectAnswer) {
        revealed++;
      }
    }

    return { revealed, total };
  }

  getAnsweredQuestionsSummary(
    answers?: PredictiveAnswerResult[]
  ): AnsweredQuestionsSummary {
    const total = this.questions.length;
    if (!answers) {
      return { total, answered: 0 };
    }

    const answered = answers.filter((x) =>
      this.questions.some((q) => q.id.equals(x.questionId))
    ).length;
    return { total, answered };
  }

  getState(): SegmentState {
    const now = new Date();
    if (!this.startedAt.utc || now < this.startedAt.utc) {
      return SegmentState.Locked;
    }

    if (this.closedAt.utc && this.closedAt.utc <= now) {
      return SegmentState.Completed;
    }

    return SegmentState.Live;
  }

  canArchive() {
    return !this.isArchived && this.getState() === SegmentState.Completed;
  }

  isAcceptingAnswers() {
    return this.getState() === SegmentState.Live;
  }

  setAnswerRevealedImmutable(
    questionId: Guid,
    isRevealed: boolean
  ): PredictiveSegment {
    return new PredictiveSegment({
      ...this,
      questions: this.questions.map((q) =>
        q.setAnswerRevealedImmutable(questionId, isRevealed)
      )
    });
  }

  getTimeZoneId(): string | undefined {
    return this.startedAt.timeZoneId ?? this.closedAt.timeZoneId;
  }

  isClosed(): boolean {
    return this.getState() === SegmentState.Completed;
  }

  getDistributionTag(): string {
    return `${getTagScope(KnownTags.PredictorSegment)}${this.id}`;
  }

  getLifeCycleEvents(): DistributionLifeCycleEvent[] {
    const title = `${this.title ?? 'Unknown Segment'}`;
    return [
      {
        label: `${title} Start`,
        value: Predictive.StartedEventName,
        entityId: this.id.toString(),
        currentEventTime: this.startedAt.utc
      },
      {
        label: `${title} End`,
        value: Predictive.EndedEventName,
        entityId: this.id.toString(),
        currentEventTime: this.closedAt.utc
      }
    ];
  }

  checkCanAwardPrizes(): SegmentPrizeCheckResult {
    let canAward = true;
    let reason: string = undefined;

    const { revealed, total } = this.getRevealedAnswersSummary();
    if (revealed !== total) {
      canAward = false;
      reason = 'Not all answers revealed.';
    }

    if (this.getState() === SegmentState.Locked) {
      canAward = false;
      reason = 'Not yet started.';
    }

    return { canAward, reason, title: this.title };
  }
}

export interface SegmentPrizeCheckResult {
  canAward: boolean;
  title: string;
  reason?: string;
}
